import Vue from 'vue';
import anime from 'animejs/lib/anime.es.js';

const AnimePlugin = {
  install(Vue, options) {
    // Making anime available in every component
    Vue.prototype.$anime = anime;
  }
};

Vue.use(AnimePlugin);

export default AnimePlugin;
