import {defaultLocale, i18nPages} from "@/i18n.config";

// This is used to redirect to the correct URL when wanting to preview a "post" collection type
// e.g. if you visit
// http://localhost:3000/preview?type=projects&locale=bg&slug=umen-zaslon
// it redirects you to
// http://localhost:3000/bg/proekt/umen-zaslon/?preview=true

// This redirect is needed because we cannot set the correct route in the cms, due to i18n plugin

export default ({app}) => {
  app.router.beforeEach((to, from, next) => {
    const {locale, slug, type} = to.query;
    const path = to.path;

    if (path.startsWith("/preview")) {
      // Set the locale based on the URL parameter
      app.i18n.setLocale(locale);
      const newPath = path.replace(
        "/preview",
        `${
          locale === defaultLocale ? "" : "/" + locale
        }${i18nPages[type + "/_slug"][locale].replace(":slug", slug)}/?preview=true`
      );

      next(newPath);
    } else {
      next();
    }
  });
};
