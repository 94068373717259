
import OnvocadoLogo1 from "~/static/logo_anim/ONVOCADO_LOGO-1.svg?inline";
import OnvocadoLogo2 from "~/static/logo_anim/ONVOCADO_LOGO-2.svg?inline";
import OnvocadoLogo3 from "~/static/logo_anim/ONVOCADO_LOGO-3.svg?inline";
import OnvocadoLogo4 from "~/static/logo_anim/ONVOCADO_LOGO-4.svg?inline";
import OnvocadoLogo5 from "~/static/logo_anim/ONVOCADO_LOGO-5.svg?inline";
import OnvocadoLogo6 from "~/static/logo_anim/ONVOCADO_LOGO-6.svg?inline";
import OnvocadoLogo7 from "~/static/logo_anim/ONVOCADO_LOGO-7.svg?inline";
import OnvocadoLogo8 from "~/static/logo_anim/ONVOCADO_LOGO-8.svg?inline";
import OnvocadoLogo9 from "~/static/logo_anim/ONVOCADO_LOGO-9.svg?inline";
import OnvocadoLogo10 from "~/static/logo_anim/ONVOCADO_LOGO-10.svg?inline";
import OnvocadoLogo11 from "~/static/logo_anim/ONVOCADO_LOGO-11.svg?inline";
import OnvocadoLogo12 from "~/static/logo_anim/ONVOCADO_LOGO-12.svg?inline";
import OnvocadoLogo13 from "~/static/logo_anim/ONVOCADO_LOGO-13.svg?inline";
import OnvocadoLogo14 from "~/static/logo_anim/ONVOCADO_LOGO-14.svg?inline";
import OnvocadoLogo15 from "~/static/logo_anim/ONVOCADO_LOGO-15.svg?inline";
import OnvocadoLogo16 from "~/static/logo_anim/ONVOCADO_LOGO-16.svg?inline";
import OnvocadoLogo17 from "~/static/logo_anim/ONVOCADO_LOGO-17.svg?inline";
import OnvocadoLogo18 from "~/static/logo_anim/ONVOCADO_LOGO-18.svg?inline";
import OnvocadoLogo19 from "~/static/logo_anim/ONVOCADO_LOGO-19.svg?inline";
import OnvocadoLogo20 from "~/static/logo_anim/ONVOCADO_LOGO-20.svg?inline";
import OnvocadoLogo21 from "~/static/logo_anim/ONVOCADO_LOGO-21.svg?inline";
import OnvocadoLogo22 from "~/static/logo_anim/ONVOCADO_LOGO-22.svg?inline";
import OnvocadoLogo23 from "~/static/logo_anim/ONVOCADO_LOGO-23.svg?inline";
import OnvocadoLogo24 from "~/static/logo_anim/ONVOCADO_LOGO-24.svg?inline";
import OnvocadoLogo25 from "~/static/logo_anim/ONVOCADO_LOGO-25.svg?inline";
import OnvocadoLogo26 from "~/static/logo_anim/ONVOCADO_LOGO-26.svg?inline";
import OnvocadoLogo27 from "~/static/logo_anim/ONVOCADO_LOGO-27.svg?inline";
import OnvocadoLogo28 from "~/static/logo_anim/ONVOCADO_LOGO-28.svg?inline";
import OnvocadoLogo29 from "~/static/logo_anim/ONVOCADO_LOGO-29.svg?inline";
import OnvocadoLogo30 from "~/static/logo_anim/ONVOCADO_LOGO-30.svg?inline";
import OnvocadoLogo31 from "~/static/logo_anim/ONVOCADO_LOGO-31.svg?inline";
import OnvocadoLogo32 from "~/static/logo_anim/ONVOCADO_LOGO-32.svg?inline";
import OnvocadoLogo33 from "~/static/logo_anim/ONVOCADO_LOGO-33.svg?inline";
import OnvocadoLogo34 from "~/static/logo_anim/ONVOCADO_LOGO-34.svg?inline";

export default {
  name: "Logo",
  components: {
    OnvocadoLogo1,
    OnvocadoLogo2,
    OnvocadoLogo3,
    OnvocadoLogo4,
    OnvocadoLogo5,
    OnvocadoLogo6,
    OnvocadoLogo7,
    OnvocadoLogo8,
    OnvocadoLogo9,
    OnvocadoLogo10,
    OnvocadoLogo11,
    OnvocadoLogo12,
    OnvocadoLogo13,
    OnvocadoLogo14,
    OnvocadoLogo15,
    OnvocadoLogo16,
    OnvocadoLogo17,
    OnvocadoLogo18,
    OnvocadoLogo19,
    OnvocadoLogo20,
    OnvocadoLogo21,
    OnvocadoLogo22,
    OnvocadoLogo23,
    OnvocadoLogo24,
    OnvocadoLogo25,
    OnvocadoLogo26,
    OnvocadoLogo27,
    OnvocadoLogo28,
    OnvocadoLogo29,
    OnvocadoLogo30,
    OnvocadoLogo31,
    OnvocadoLogo32,
    OnvocadoLogo33,
    OnvocadoLogo34,
  },
  data() {
    return {
      logos: [
        OnvocadoLogo1,
        OnvocadoLogo2,
        OnvocadoLogo3,
        OnvocadoLogo4,
        OnvocadoLogo5,
        OnvocadoLogo6,
        OnvocadoLogo7,
        OnvocadoLogo8,
        OnvocadoLogo9,
        OnvocadoLogo10,
        OnvocadoLogo11,
        OnvocadoLogo12,
        OnvocadoLogo13,
        OnvocadoLogo14,
        OnvocadoLogo15,
        OnvocadoLogo16,
        OnvocadoLogo17,
        OnvocadoLogo18,
        OnvocadoLogo19,
        OnvocadoLogo20,
        OnvocadoLogo21,
        OnvocadoLogo22,
        OnvocadoLogo23,
        OnvocadoLogo24,
        OnvocadoLogo25,
        OnvocadoLogo26,
        OnvocadoLogo27,
        OnvocadoLogo28,
        OnvocadoLogo29,
        OnvocadoLogo30,
        OnvocadoLogo31,
        OnvocadoLogo32,
        OnvocadoLogo33,
        OnvocadoLogo34,
      ],
      currentLogo: OnvocadoLogo1,
      animationDirection: 'forward',
      animationIndex: 0,
      isAnimating: false,
    };
  },
  methods: {
    startAnimation() {
      if (this.isAnimating && this.animationDirection === 'forward' || this.animationIndex === 15) return;
      this.animationIndex = 0; // Start from logo 1
      this.animationDirection = 'forward';
      this.loopLogos();
    },
    reverseAnimation() {
      if (this.isAnimating && this.animationDirection === 'reverse' && this.animationIndex === 0) return;
      this.animationIndex = 15; // Start from logo 30
      this.animationDirection = 'reverse';
      this.loopLogos();
    },
    loopLogos() {
      if (!this.isAnimating) {
        this.isAnimating = true;
      }
      this.$anime.remove(this.$el);
      this.currentLogo = this.logos[this.animationIndex];

      this.$anime({
        targets: this.$el,
        duration: 30,
        easing: 'easeInOutQuad',
        complete: () => {
          if (this.animationDirection === 'forward' && this.animationIndex < 15) {
            this.animationIndex++;
            this.loopLogos();
          } else if (this.animationDirection === 'reverse' && this.animationIndex > 14 && this.animationIndex < 33) {
            this.animationIndex++;
            this.loopLogos();
          } else {
            this.isAnimating = false; // Reset the flag when animation ends
          }
        }
      });
    }
  }
};
