import Vue from 'vue';
import Lenis from '@studio-freight/lenis';

Vue.prototype.$initLenis = (container) => {
  return new Lenis({
    // wrapper: container,
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    // orientation: 'vertical', // vertical, horizontal
    // gestureOrientation: 'vertical', // vertical, horizontal, both
    smoothWheel: true,
    // wheelMultiplier: 1,
    // smoothTouch: true,
    // touchMultiplier: 1,
    // infinite: false,
  });
};
