import Vue from 'vue';
import { decodeBlurHash, getBlurHashAverageColor } from "fast-blurhash";

const Blurhash = {
  install(Vue, options) {
    Vue.prototype.$blurhashDecode = decodeBlurHash;
    Vue.prototype.$getBlurHashAverageColor = getBlurHashAverageColor;
  },
};

Vue.use(Blurhash);
