
export default {
  name: "CookieAgreementPopup",
  data() {
    return {
      show: false,
      necessary: true,
      statistics: true,
      marketing: true
    }
  },
  computed: {
    detailedView() {
      return this.$route.query.detailedConsent === 'true';
    }
  },
  mounted() {
    const agreed = this.$cookies.get('agreed_on_cookie_usage')

    if (!agreed) {
      setTimeout(() => {
        this.show = true
      }, 1000)
    } else {
      this.updateGtagConsent(agreed);
    }
  },
  methods: {
    accept() {
      const consent = {
        preferences: this.necessary,
        statistics: this.statistics,
        marketing: this.marketing
      };

      this.$cookies.set('agreed_on_cookie_usage', consent, {
        maxAge: 60 * 60 * 24 * 30 // About 1 month.
      });

      this.show = false;
      this.updateGtagConsent(consent);
    },
    acceptSimple() {
      const consent = {
        necessary: true,
        statistics: true,
        marketing: true
      };

      this.$cookies.set('agreed_on_cookie_usage', consent, {
        maxAge: 60 * 60 * 24 * 30 // About 1 month.
      });

      this.show = false;
      this.updateGtagConsent(consent);
    },
    hide() {
      this.show = false
    },
    updateGtagConsent(consent) {
      if (process.env.NODE_ENV !== 'production') return;
      function gtag() {
        dataLayer.push(arguments);
      }

      gtag('consent', 'update', {
        'ad_storage': consent.marketing ? 'granted' : 'denied',
        'analytics_storage': consent.statistics ? 'granted' : 'denied'
      });
    }
  }
}
