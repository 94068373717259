export const state = () => ({
  loadingAnimationFinished: false,
  previewMode: false,
  isMobile: false,
  isLenisScrollInitialized: false,
  showNav: true,
  scrollToTarget: null,
})

export const mutations = {
  finishLoading(state) {
    state.loadingAnimationFinished = true;
  },
  setPreviewMode(state, value) {
    state.previewMode = value;
  },
  setIsMobile(state, value) {
    state.previewMode = value;
  },
  setLenisScrollInitialized(state, value) {
    state.isLenisScrollInitialized = value;
  },
  setShowNav(state, bool) {
    state.showNav = bool;
  },
  setScrollToTarget(state, target) {
    state.scrollToTarget = target;
  },
}

export const actions = {
  triggerScrollTo({commit}, target) {
    commit('setScrollToTarget', target);
  }
}
